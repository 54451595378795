<template>
    <div class="bbb">
        <div id="Interrogation" class="Patient">
            <van-nav-bar
                title="就诊人"
                left-arrow
                @click-left="onClickLeft"
            />
            <van-search
            v-model="value"
            placeholder="请输入搜索关键词"
            />
            <van-button @click="tj" class="margin_top"  color="linear-gradient(to right, #02d3d9, #09e0c0)" block>添加就诊人</van-button>

            <van-nodata  v-if="list.length == 0" :figure="imgUrl[2]" title="暂无就诊人信息" />
            <div v-else>
                <div class="bpd margin_top van-person-card" v-for="(item, index) in list">
                    <div class="van-person-card__avatar">
                        <div class="van-avatar"><img style="width: 50px; height: 50px;" :src="img" alt=""></div>
                    </div>
                    <div class="van-person-card__wrapper">
                        <div class="van-row van-row--flex van-row--align-center van-row--justify-space-between van-person-card__base">
                            <div class="van-row van-row--flex van-row--align-center">
                                <div><span class="van-person-card__title">姓名：{{item.name}}</span><span class="van-person-card__sub-title">性别：{{  item.sex == '0' ? '女' : '男'}}</span></div>
                            </div>
                        </div>
                        <div class="van-person-card__content">
                            <div class="van-person-card__content-item">
                                <!-- 出生日期：{{ item.birthday }} -->
                            </div>
                            <div>
                                <el-button size="small" style="margin-right: 1px;" @click="edit(item)"  type="success">查看/上传复诊证明</el-button> 
                                <el-popconfirm
                                    @confirm="Patient_Delete(item)"
                                        title="您确定删除此就诊人吗？"
                                    >
                                    <el-button size="small" slot="reference" type="danger">删除</el-button>
                                </el-popconfirm>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
       
        <footer_/>
    </div>
  </template>
  <script>
  import footer_ from '../components/footer.vue'
  import mixin from "@/utils/mixin";
  export default {
    mixins: [mixin],
    components: {
      footer_,
    },
    data() {
      return {
        list:[],
        value:'',
        img:require('../assets/image/avatar1.png')
      }
    },
    methods: {
        onClickLeft(){
            this.$router.back(-1)
        },
        edit(item){
            this.$router.push({
                name:'post_Patient',
                query:{
                    id:item.id,
                }
            })
        },
        loadList(){
            let params = {
              search_db: 'Patient',
              query_type: `equal`,
            };

            let data = {
              query: [
                {
                  fild: { user_account: JSON.parse(localStorage.getItem('user')).user_account },
                  type: '=',
                  condition: '&&',
                }
              ],
            };

            this.$api.article.query2(params, data).then(res=>{
                this.list = res.data.result
            })
        },
        tj(){
            this.$router.push('/post_Patient')
        },
        Patient_Delete(item){
            this.putstate('Patient','id',item.id,{deleted:1})
        }
    },
    created() {
        if(localStorage.getItem('user')){
            this.loadList()
        }
    },
    mounted(){
   

    }
  }
  </script>
  <style scopde lang="less">
  .van-person-card__content{
    display: flex;
    justify-content: space-between;
  }
    .Patient .van-nodata{
        top: 20vh !important;
    }
    .PatientBtn{
        // margin-top: 10rem !important;
    }
  </style>